<script setup lang="ts">
import { ref } from 'vue'

import './_index.scss'

const { t, tm, rt } = useI18n()

const benefits: any = ref(tm('content'))

const simulatorLink = () => navigateTo('/venta/propiedades')
</script>

<script lang="ts">
export default {
  name: 'HomeBenefits'
}
</script>

<template>
  <section class="home-benefits my-40 w-full max-w-[97rem] mx-auto px-24 lg:px-0">
    <div class="grid gap-8 mb-32">
      <span class="text-14 text-carbon-600 tracking-[.05rem]">
        {{ t('sectionName') }}
      </span>

      <h3 class="max-w-[50rem] text-32 text-carbon-800 font-semibold leading-[3.5rem]">
        <span>
          {{ t('title.part1') }}
        </span>

        <span class="text-primary-600">
          {{ t('title.part2') }}
        </span>

        <span class="font-normal">
          {{ t('title.part3') }}
        </span>
      </h3>
    </div>

    <div class="grid gap-16 md:grid-cols-2 md:gap-24">
      <div
        v-for="(benefit, index) in benefits"
        :key="index"
        class="w-full home-benefits__card md:max-h-400">
        <div class="grid px-24 pt-24 pb-16 text-white md:h-full lg:px-40 lg:pt-40 lg:pb-20 rounded-8 auto-rows-max">
          <span class="home-benefits__card-title-category mb-12 uppercase text-12 font-semibold tracking-[.05rem]">
            {{ rt(benefit.title) }}
          </span>

          <p class="mb-20 text-14 lg:text-18 leading-24">
            {{ rt(benefit.description) }}
          </p>

          <button
            v-if="rt(benefit.cta)"
            :data-lh-id="`home-benefits-price-cta-${benefit.cta}`"
            class="flex items-center gap-12 font-medium text-16 w-max justify-self-end"
            :aria-label="rt(benefit.cta)"
            @click="simulatorLink()">
            {{ rt(benefit.cta) }}
            <span class="w-20 h-20 pointer-events-none fill-brand-600">
              <NuxtIsland name="IconRight" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n src="./i18n.json" lang="json" />
