<script lang="ts" setup>
import { computed } from 'vue'
import '@lahaus-roomie/roomie/src/components/RoomieButtonLink/_index.scss'
import './_index.scss'

import { AppImage } from '#components'
import { ratingImage } from '@/config/google-review'

const { t } = useI18n()

const pictures = [
  'https://lahaus.imgix.net/static/home/bg-home-building-left.png',
  'https://lahaus.imgix.net/static/home/bg-home-building-left-2.png',
  'https://lahaus.imgix.net/static/home/bg-home-building-left-3.png'
]

const getImageSources = (imageSrc: string, leftSide = true) => {
  let imagesSizes = [
    { width: 410, height: 410 },
    { width: 580, height: 580 }
  ]
  if (!leftSide) {
    imagesSizes = [
      { width: 437, height: 398 },
      { width: 146, height: 618 }
    ]
  }

  return [
    {
      imageWidth: imagesSizes[0].width,
      imageHeight: imagesSizes[0].height,
      mediaWidth: 768,
      src: `${imageSrc}?auto=compress,enhance,format&w=${imagesSizes[0].width}&h=${imagesSizes[0].height}&fit=crop&crop=center`
    }, {
      imageWidth: imagesSizes[1].width,
      imageHeight: imagesSizes[1].height,
      mediaWidth: 1920,
      src: `${imageSrc}?auto=compress,enhance,format&w=${imagesSizes[1].width}&h=${imagesSizes[1].height}&fit=crop&crop=right`
    }
  ]
}

const calificationImg = computed(() => ratingImage)
</script>

<script lang="ts">
export default {
  name: 'AppHero'
}
</script>

<template>
  <section class="app-hero pt-48 lg:pt-108 lg:min-h-[71rem] grid overflow-hidden">
    <div class="app-hero__content w-full max-w-[97rem] mx-auto flex flex-col relative">
      <div class="px-24 xl:px-0 custom-animate-fade-in-up z-10 lg:h-full grid grid-rows-[max-content_max-content_max-content_1fr]">
        <h1 class="mb-12 md:mb-16 lg:w-4/5">
          <p class="text-accent-700 text-32 font-semibold leading-[3.6rem] md:leading-64  md:text-56">
            {{ t('title.part1') }}
          </p>

          <p class="text-32 text-carbon-800 font-semibold leading-[3.6rem] md:leading-64 md:text-56 lg:whitespace-nowrap">
            <span>
              {{ t('title.part2') }}
            </span>
          </p>

          <span class="text-32 leading-[3.6rem] md:leading-64 md:text-56">
            {{ t('title.part3') }}
          </span>
        </h1>

        <h2 class="md:max-w-[50rem] xl:max-w-[54rem] mb-16 text-14 text-carbon-600  md:text-20">
          {{ t('description') }}
        </h2>

        <NuxtLink
          id="home-hero-cta"
          data-lh-id="home-hero-cta"
          to="/venta/propiedades"
          class="roomie-button w-max"
          :aria-label="t('cta_string')">
          {{ t('cta_string') }}
        </NuxtLink>

        <div
          class="w-max pt-18 pb-24 z-10 self-end"
          data-lh-id="home-hero-google-reviews">
          <div class="grid grid-flow-col pointer-events-none">
            <img
              v-for="(img, index) in calificationImg"
              :key="index"
              :src="img.src"
              :alt="img.alt"
              :width="img.width"
              :height="img.height"
              :class="img.class"
              class="pointer-events-none">

            <p class="text-18 lg:text-24 text-carbon-300 font-medium pr-lh-8 col-start-2 pointer-events-none">
              {{ t('qualification') }}
            </p>
          </div>

          <p class="text:14 lg:text-16 pt-4 text-carbon-600 pointer-events-none">
            {{ t('total_reviews') }}
          </p>
        </div>
      </div>

      <div class="app-hero__figure-container custom-animate-fade-in-up w-fit m-auto pt-4 -mt-30 lg:m-0 grid place-self-end md:grid-cols-[60rem_1fr] md:gap-30 items-end cutom-animate-fade-in-up overflow-hidden lg:absolute top-20 left-[55rem] h-fit z-10">
        <div class="app-hero__figure">
          <div class="w-full h-[calc(100%-2px)] relative top-2 overflow-hidden pointer-events-none">
            <AppImage
              v-for="(picture, index) in pictures"
              :key="`left-image-${index}`"
              alt="hero picture left"
              :sources="getImageSources(picture)"
              class-img="object-cover w-full h-full pointer-events-none"
              class="app-hero__image-animation w-full h-full absolute -z-10 pointer-events-none"
              :src="getImageSources(picture)[0].src" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">

.custom-animate-fade-in-up {
  animation: custom-fade-in-up 2s;
}

@keyframes custom-fade-in-up {
  from {
    transform: translateY(80%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>

<i18n src="./i18n.json" lang="json" />
