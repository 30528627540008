export const imageByType = (picturesList: any[], type: 'cover' | 'gallery') => {
  const coverImage = picturesList.find((picture: any) => picture.type === type)

  if (!coverImage) return null

  return coverImage
}

export const residentialComplexFilters = (countryCode: string) => {
  return {
    fields: ['code', 'name', 'attributes.pictures_v2', 'ims.bedrooms_stats', 'ims.typologies', 'ims.delivered_at', 'seo.path', 'location.neighborhood', 'location.city', 'attributes.currency'],
    filters: {
      and: [
        { 'ims.is_completed': true },
        { 'ims.external_status': 'active' },
        { 'location.country_code': countryCode.toUpperCase() }
      ]
    },
    pagination: {
      limit: 5,
      offset: 0
    },
    sort: [
      'rank_score:desc',
      'ims.min_price:asc'
    ]
  }
}
