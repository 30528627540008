<script setup lang="ts">
import RoomIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/room.svg'
import ImageShowcase from '@/components/ImageShowcase/index.vue'
import type {
  UniqueOpportunitiesProjectCardComponentModule
} from '@/components/BrandedV2/UniqueOpportunities/ProjectCard/types'

defineProps<UniqueOpportunitiesProjectCardComponentModule.Props>()
</script>

<script lang="ts">
export default {
  name: 'UniqueOpportunitiesProjectCard'
}
</script>

<template>
  <div :class="['unique-opportunities-project-card', { 'unique-opportunities-project-card__inactive': isInactive }]">
    <div class="unique-opportunities-project-card__cover">
      <ImageShowcase
        class="w-full h-full"
        :images="coverImages"
        lazy-load />
    </div>

    <div class="unique-opportunities-project-card__rooms">
      <div class="unique-opportunities-project-card__rooms-information gap-12 md:gap-10 md:min-h[calc(50% - 10px)]">
        <RoomIcon class="fill-current-color text-pine-600 w-40 h-40 md:w-68 md:h-68" />

        <h5 class="display-6 md:display-5 text-pine-600">
          {{ bedroomRange }}
        </h5>
      </div>

      <div class="unique-opportunities-project-card__rooms-picture md:min-[calc(50% - 10px)]">
        <div class="overflow-auto w-full rounded-[2.4rem]">
          <ImageShowcase
            class="w-full h-full"
            :images="galleryImages"
            lazy-load />
        </div>
      </div>
    </div>

    <div class="unique-opportunities-project-card__information">
      <div class="mb-24 md:mb-auto">
        <div class="unique-opportunities-project-card__tag">
          <span class="unique-opportunities-project-card__tag-title">
            {{ deliveredAt }}
          </span>
        </div>

        <h3 class="display-6 md:display-5 mb-8 md:mb-10">
          {{ name }}
        </h3>

        <p class="md:body-1-sb body-1-m">
          {{ neighborhood }}, {{ city }}
        </p>
      </div>

      <div class="flex flex-row md:flex-col gap-8">
        <p class="hl-2-sb md:display-6">
          {{ priceLabel }}
        </p>

        <h3 class="hl-2-sb md:display-6">
          {{ price }} <span class="body-1-m">{{ currency }}</span>
        </h3>
      </div>
    </div>
  </div>
</template>

<style src="./_index.scss" lang="scss"></style>
