<script lang="ts" setup>
import { ref, onMounted, computed, onUnmounted, watch } from 'vue'
import BackIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/back.svg'
import NextIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/next.svg'
import RoomieNavigationButton from '@lahaus-roomie/roomie/src/components/RoomieNavigationButton/index.vue'

import RoomieNavigationDots from '@lahaus-roomie/roomie/src/components/RoomieNavigationDots/index.vue'
import { ScrollPosition, type Props } from './types'

const props = defineProps<Props>()

let completeScrollTimeout: ReturnType<typeof setTimeout> | undefined
let resizeObserver: ResizeObserver | undefined
const currentLeftScrollPosition = ref(0)
const requireScroll = ref(true)
const itemsContainer = ref<HTMLDivElement>()
const currentActiveElementIndex = ref(0)
const numberOfElements = ref(0)

const hasLeftScroll = computed(() => currentLeftScrollPosition.value > 0)
const hasRightScroll = computed(() => itemsContainer.value &&
  requireScroll.value &&
  currentLeftScrollPosition.value < (itemsContainer.value.scrollWidth - itemsContainer.value.clientWidth) * 99 / 100
)

const realActiveElementIndex = computed(() => {
  if (!props.isInfinite || !itemsContainer.value) return currentActiveElementIndex.value
  if (currentActiveElementIndex.value < itemsContainer.value.children.length / 2) return currentActiveElementIndex.value
  return currentActiveElementIndex.value - itemsContainer.value.children.length / 2
})

watch(() => currentLeftScrollPosition.value, () => {
  if (!itemsContainer.value) return
  const containerCenter = itemsContainer.value.offsetLeft + itemsContainer.value!.offsetWidth / 2

  const index = Array.from(itemsContainer.value.children).findIndex((item, index) => {
    const itemRect = item.getBoundingClientRect()
    return containerCenter > itemRect.left && containerCenter < itemRect.right
  })

  currentActiveElementIndex.value = index !== -1 ? index : currentActiveElementIndex.value
})

const hasToShowLeftArrow = computed(() => !props.hideButtons || hasLeftScroll.value)
const hasToShowRightArrow = computed(() => !props.hideButtons || hasRightScroll.value)

const onScroll = () => {
  currentLeftScrollPosition.value = itemsContainer.value!.scrollLeft

  if (completeScrollTimeout) {
    clearTimeout(completeScrollTimeout)
    completeScrollTimeout = undefined
  }

  if (props.centerActiveElement && props.isInfinite) {
    completeScrollTimeout = setTimeout(() => {
      clearTimeout(completeScrollTimeout)
      completeScrollTimeout = undefined
      scrollToElement(currentActiveElementIndex.value, ScrollPosition.Center, true)
    }, 100)
  }

  if (!props.isInfinite) return

  const children = Array.from(itemsContainer.value!.children)
  if (itemsContainer.value!.scrollLeft >= itemsContainer.value!.scrollWidth - itemsContainer.value!.clientWidth - 1) {
    const cardIndex = children.length / 2 - 1
    scrollToElement(cardIndex, ScrollPosition.End, false)
  } else if (itemsContainer.value!.scrollLeft <= 0) {
    scrollToElement(children.length / 2, ScrollPosition.Start, false)
  }
}

const scrollToElement = (index: number, position: ScrollPosition, animated?: boolean) => {
  const card = itemsContainer.value?.children.item(index) as HTMLElement

  if (!card || card === null || !itemsContainer.value) return
  let scrollLeft = 0

  switch (position) {
  case ScrollPosition.Center:
    scrollLeft = card.offsetLeft - (itemsContainer.value.clientWidth / 2) + (card.clientWidth / 2)
    break
  case ScrollPosition.Start:
    scrollLeft = card.offsetLeft
    break
  case ScrollPosition.End:
    scrollLeft = card.offsetLeft + card.clientWidth - itemsContainer.value.clientWidth
    break
  }

  itemsContainer.value.scroll({
    left: scrollLeft,
    behavior: animated ? 'smooth' : 'auto'
  })
}

const getScrollSize = () => {
  if (props.scrollWidth?.includes('%')) {
    return itemsContainer.value!.clientWidth * parseInt(props.scrollWidth) / 100
  }

  if (props.scrollWidth?.includes('px')) {
    return parseFloat(props.scrollWidth)
  }

  return itemsContainer.value!.children.item(0)!.clientWidth
}

const scrollToNext = () => {
  if (props.centerActiveElement && props.isInfinite) {
    return scrollToElement(currentActiveElementIndex.value + 1, ScrollPosition.Center, true)
  }

  itemsContainer.value?.scroll({
    left: itemsContainer.value!.scrollLeft + getScrollSize(),
    behavior: 'smooth'
  })
}

const scrollToPrevious = () => {
  if (props.centerActiveElement && props.isInfinite) {
    scrollToElement(currentActiveElementIndex.value - 1, ScrollPosition.Center, true)
  }

  itemsContainer.value?.scroll({
    left: itemsContainer.value!.scrollLeft - getScrollSize(),
    behavior: 'smooth'
  })
}

const watchScrollChanges = () => {
  itemsContainer.value!.addEventListener('scroll', onScroll)

  resizeObserver = new ResizeObserver(() => {
    requireScroll.value = itemsContainer.value!.scrollWidth > itemsContainer.value!.clientWidth
    onScroll()
  })

  resizeObserver!.observe(itemsContainer.value!)
}

watch(itemsContainer, (value) => {
  const childrenElements = value?.children
  if (childrenElements) {
    numberOfElements.value = Array.from(childrenElements).length

    if (props.isInfinite) {
      numberOfElements.value = numberOfElements.value / 2
    }
  }
})

onMounted(() => {
  watchScrollChanges()
  if (props.isInfinite || props.centerActiveElement) {
    itemsContainer.value!.scrollLeft = 1
    currentLeftScrollPosition.value = 1
  }

  if (props.scrollOnClick) {
    Array.from(itemsContainer.value!.children).forEach((item, index) => {
      item.addEventListener('click', () => scrollToElement(index, ScrollPosition.Center, true))
    })
  }
})

onUnmounted(() => {
  resizeObserver?.disconnect()
  itemsContainer.value?.removeEventListener('scroll', onScroll)
})
</script>

<script lang="ts">
export default {
  name: 'AppCarousel'
}
</script>

<template>
  <div
    :id="id"
    class="w-full">
    <div class="app-carousel">
      <ClientOnly>
        <slot
          v-if="$slots.leftButton"
          name="leftButton"
          :has-left-scroll="hasLeftScroll"
          :scroll-to="scrollToPrevious" />

        <RoomieNavigationButton
          v-else-if="hasToShowLeftArrow"
          :id="`${id}-button-left`"
          :disabled="!hasToShowLeftArrow"
          class="absolute z-10 -translate-y-1/2 top-1/2 left-4"
          aria-label="Atrás"
          size="lg"
          variant="outlined"
          :class="buttonClasses?.left"
          @click="scrollToPrevious">
          <BackIcon />
        </RoomieNavigationButton>
      </ClientOnly>

      <div
        ref="itemsContainer"
        :class="itemsContainerClasses"
        class="carousel-container">
        <template
          v-for="_, index in isInfinite && requireScroll ? 2 : 1"
          :key="index">
          <slot
            v-if="$slots['items']"
            ref="items"
            name="items"
            :active-element-index="realActiveElementIndex" />

          <slot
            v-else
            ref="items"
            name="default"
            :active-element-index="realActiveElementIndex"
            :has-left-scroll="hasLeftScroll"
            :has-right-scroll="hasRightScroll"
            :scroll-to-next="scrollToNext"
            :scroll-to-previous="scrollToPrevious" />
        </template>
      </div>

      <ClientOnly>
        <slot
          v-if="$slots.rightButton"
          name="rightButton"
          :has-right-scroll="hasRightScroll"
          :scroll-to="scrollToNext" />

        <RoomieNavigationButton
          v-else-if="hasToShowRightArrow"
          :id="`${id}-button-right`"
          :disabled="!hasToShowRightArrow"
          class="absolute z-10 -translate-y-1/2 top-1/2 right-4"
          :class="buttonClasses?.right"
          aria-label="Siguiente"
          variant="outlined"
          size="lg"
          @click="scrollToNext">
          <NextIcon />
        </RoomieNavigationButton>
      </ClientOnly>
    </div>

    <div class="flex justify-center mt-24 md:hidden">
      <RoomieNavigationDots
        :current-position="realActiveElementIndex"
        :number-of-images="numberOfElements"
        transparent-background />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-carousel {
  @apply relative flex flex-row items-center justify-center w-full overflow-hidden;

  .roomie-button-navigation {
    @apply hidden md:block
  }
}

.carousel-container {
  scrollbar-width: none;

  @apply flex flex-row overflow-x-auto overflow-y-hidden;

  &::-webkit-scrollbar {
    @apply hidden;
  }
}
</style>
