<script setup lang="ts">
import IconVerified from '@lahaus-roomie/roomie/src/assets/icons/verified.svg'
import './_index.scss'
import { ref } from 'vue'

const { tm, rt, t } = useI18n()

const testimonials: any = tm('testimonials')

const showVideo = ref([
  { show: false },
  { show: false },
  { show: false },
  { show: false }
])

function playVideo (index:number) {
  showVideo.value[index].show = !showVideo.value[index].show
}
</script>

<script lang="ts">
export default {
  name: 'HomeTestimonials'
}
</script>

<template>
  <section class="home-testimonials w-full max-w-screen-lg 2xl:max-w-screen-xl mx-auto px-24 py-56 xl:px-0">
    <div>
      <span class="text-14 text-carbon-600 tracking-[.05rem]">
        {{ t('sectionName') }}
      </span>

      <h3 class="home-testimonials__title text-32 text-carbon-800 font-semibold leading-[3.5rem] pt-12">
        <span>
          {{ t('title.part1') }}
        </span>

        <span class="home-testimonials__title__part2 text-accent-700">
          {{ t('title.part2') }}
        </span>
      </h3>

      <p class="text-32 text-carbon-800 leading-[3.5rem">
        {{ t('title.part3') }}
      </p>
    </div>

    <div class="grid gap-16 md:grid-cols-2 xl:grid-cols-4 pt-32">
      <div
        v-for="item, index in testimonials"
        :key="index"
        class="overflow-hidden even:flex even:flex-col-reverse">
        <div class="home-testimonials__video relative rounded-16">
          <div class="absolute left-10 top-12">
            <img
              v-if="!showVideo[index]?.show"
              class="w-40 h-40 md:w-56 md:h-56 fill-white"
              :src="t('imgPlay')"
              data-lh-id="play-testimonial-video-home"
              alt="ícono play"
              @click="playVideo(index)">
          </div>

          <ClientOnly>
            <img
              v-if="!showVideo[index]?.show"
              class="w-full h-full object-cover"
              :class="rt(item.classRoundedTop)"
              :src="rt(item.gif)"
              data-lh-id="play-testimonial-video-home"
              alt="video corto del testimonio"
              @click="playVideo(index)">

            <video
              v-else
              ref="videoElement"
              description=""
              subtitles=""
              class="h-full w-full object-cover z-0"
              :class="rt(item.classRoundedTop)"
              :src="rt(item.video)"
              title=""
              autoplay
              controls
              allowfullscreen />
          </ClientOnly>
        </div>

        <div
          class="home-testimonials__description py-14 pl-14 md:px-14 flex flex-col justify-between"
          :class="rt(item.classDescription)">
          <div class="flex items-center justify-between pr-14 md:pr-0">
            <p class="text-14 md:text-16 font-bold">
              {{ rt(item.zone) }}
            </p>

            <img
              :src="t('imgUrl')"
              alt="ícono de forma"
              class="w-32 h-32">
          </div>

          <div>
            <p class="home-testimonials__description__testimonial text-18 font-bold pr-16">
              {{ rt(item.description) }}
            </p>
          </div>

          <div class="flex items-center gap-10">
            <IconVerified class="fill-accent-600 w-18 h-18" />

            <p class="text-14 font-bold">
              {{ rt(item.name) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n src="./i18n.json" lang="json" />
