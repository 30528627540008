import { computed, ref, watch } from 'vue'
import useTypesense from '@/composables/useTypesense'
import projectMapper from '@/components/BrandedV2/UniqueOpportunities/use-unique-opportunities/project-mapper'
import { residentialComplexFilters } from '@/components/BrandedV2/UniqueOpportunities/utils'
import type {
  UniqueOpportunitiesComposableModule
} from '@/components/BrandedV2/UniqueOpportunities/use-unique-opportunities/types'
import { REQUEST_STATUS_LIST } from '@/utils/request-status'
import { ACTION_LIST } from '@/composables/useTypesense/enums'

const useUniqueOpportunities = (countryCode: string) => {
  const { data, fetchResidentialComplex, requestStatus, currentAction } = useTypesense()
  const projects = ref<UniqueOpportunitiesComposableModule.ProjectEntity[]>([])

  const fetchProjects = async () => {
    await fetchResidentialComplex(residentialComplexFilters(countryCode))
  }

  const isLoading = computed((): boolean => {
    return (requestStatus.value === REQUEST_STATUS_LIST.LOADING && currentAction.value === ACTION_LIST.FETCH_RESIDENTIAL_COMPLEX)
  })

  watch(data, (newValue): void => {
    const projectList = newValue?.rows || []
    if (projectList.length === 0) {
      projects.value = []
      return
    }

    projects.value = projectList.map(projectMapper)
  })

  return {
    fetchProjects,
    isLoading,
    projects
  }
}

export default useUniqueOpportunities
