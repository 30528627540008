<script setup lang="ts">
import { onBeforeMount } from 'vue'
import AppCarousel from '@/components/BrandedV2/AppCarousel/index.vue'
import UniqueOpportunitiesProjectCard from '@/components/BrandedV2/UniqueOpportunities/ProjectCard/index.vue'
import { getDeliveryTermLabel } from '@/utils/project'
import useUniqueOpportunities from '@/components/BrandedV2/UniqueOpportunities/use-unique-opportunities'
const { locale, t, n } = useI18n()
const { projects, fetchProjects, isLoading } = useUniqueOpportunities(locale.value)

const goToProjectDetail = (url: string): void => {
  navigateTo(url, {
    open: {
      target: '_blank'
    }
  })
}

onBeforeMount(async () => {
  await fetchProjects()
})
</script>

<script lang="ts">
export default {
  name: 'UniqueOpportunities'
}
</script>

<template>
  <div class="unique-opportunities flex flex-col items-center">
    <p
      v-if="isLoading"
      key="unique-opportunities-loader"
      class="hl-2-sb">
      Cargando...
    </p>

    <template v-else>
      <div class="flex items-center justify-center mb-24 md:mb-48 px-40">
        <h4 class="flex flex-col md:flex-row display-6 md:display-4 text-center text-pine-600 md:gap-8">
          <span>
            {{ t("title.1") }}
          </span>

          <span>
            {{ t("title.2") }}
          </span>
        </h4>
      </div>

      <AppCarousel
        id="unique-opportunities-carousel"
        key="unique-opportunities-carousel"
        class="w-full md:!w-[90.93%]"
        is-infinite
        items-container-classes="gap-[1.6rem] md:gap-[12rem]"
        center-active-element>
        <template #items="{ activeElementIndex }">
          <UniqueOpportunitiesProjectCard
            v-for="(project, index) in projects"
            :key="index + project.code"
            class="cursor-pointer"
            :is-inactive="activeElementIndex !== index"
            :cover-images="project.coverImage ? [{...project.coverImage, alt: t('coverOf', { name: project.name })}] : []"
            :gallery-images="project.galleryImage ? [{...project.galleryImage, alt: t('coverOf', { name: project.name })}] : []"
            :bedroom-range="project.bedroomRange"
            :delivered-at="getDeliveryTermLabel(project.deliveredAt, t)"
            :price="n(project.price, 'currency')"
            :currency="project.currency"
            :city="project.city"
            :price-label="t('priceLabel')"
            :neighborhood="project.neighborhood"
            :name="project.name"
            @click="goToProjectDetail(project.url)" />
        </template>
      </AppCarousel>
    </template>

    <div class="flex items-center justify-center mt-24 px-40 md:px-0">
      <p class="body-2-sb md:body-2-m text-pine-600 text-center">
        {{ t("disclaimer") }}
      </p>
    </div>
  </div>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
