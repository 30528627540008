<script setup lang="ts">
import AppCarousel from '@/components/BrandedV2/AppCarousel/index.vue'

const { rt, tm } = useI18n()

const slideList: {name: string; description: string}[] = tm('slides')
</script>
<script lang="ts">
export default {
  name: 'LaHausMedia'
}
</script>

<template>
  <div class="la-haus-media-section">
    <AppCarousel
      id="la-haus-media-carousel"
      key="la-haus-media-carousel"
      is-infinite
      class="la-haus-media-section__carousel"
      items-container-classes="la-haus-media-section__items"
      center-active-element>
      <template #items="{ activeElementIndex }">
        <div
          v-for="(slide, index) in slideList"
          :key="slide.name"
          :class="[{'la-haus-media-section__item--inactive': activeElementIndex !== index}]"
          class="la-haus-media-section__item">
          <img
            :src="`https://assets.lahaus.com/static/svgs/logos/${rt(slide.name)}.svg`"
            :alt="`Logo de ${rt(slide.name)}`">

          <p class="la-haus-media-section__item--text">
            {{ rt(slide.description) }}
          </p>
        </div>
      </template>
    </AppCarousel>
  </div>
</template>

<style src="./_index.scss" lang="scss"></style>

<i18n src="./i18n.json"></i18n>
