<script setup lang="ts">
import { ref } from 'vue'
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue'
import RoomieButtonLink from '@lahaus-roomie/roomie/src/components/RoomieButtonLink/index.vue'
import VerifiedIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/verified.svg'
import LaHausVerifiedLogo from '@lahaus-roomie/roomie/src/assets/icons/v2/la-haus-verificado-logo.svg'

const { t, rt, tm } = useI18n()

const cardsList: {title: string; description: string}[] = tm('cards')

const truncateText = (text: string, length: number) => {
  return text.length > length ? text.substring(0, length) + '...' : text
}

const expanded = ref(cardsList.map(() => false))

const toggleExpand = (index: number): void => {
  expanded.value[index] = !expanded.value[index]
}

const beforeEnter = (el: HTMLElement) => {
  el.style.maxHeight = '0px'
  el.style.opacity = '0'
}

const enter = (el: HTMLElement) => {
  el.style.transition = 'max-height 0.5s ease-in-out, opacity 0.3s ease-in-out'
  el.style.maxHeight = el.scrollHeight + 'px'
  el.style.opacity = '1'
}

const leave = (el: HTMLElement) => {
  el.style.transition = 'max-height 0.5s ease-in-out, opacity 0.3s ease-in-out'
  el.style.maxHeight = '0px'
  el.style.opacity = '0'
}
</script>

<script lang="ts">
export default {
  name: 'LaHausVerified'
}
</script>

<template>
  <div class="la-haus-verified-section">
    <div class="la-haus-verified-section__header">
      <h3 class="flex gap-8 mb-lh-32 md:mb-lh-40">
        <span>
          <span class="display-6 md:display-4">
            {{ t('title.1') }}
          </span>

          <span class="display-regular-6 md:display-regular-4">
            {{ t('title.2') }}
          </span>
        </span>

        <RoomieIcon custom-styles="block w-16 h-16 md:w-30 md:h-30">
          <LaHausVerifiedLogo />
        </RoomieIcon>
      </h3>

      <p class="body-2-sb md:display-6">
        {{ t('description') }}
      </p>
    </div>

    <div
      class="grid grid-cols-1 gap-16"
      :class="{'md:grid-cols-3': cardsList.length > 4, 'md:grid-cols-4': cardsList.length === 4 }">
      <div
        v-for="(card, index) in cardsList"
        :key="`${card.title}-${index}`"
        class="la-haus-verified-section__card">
        <div class="la-haus-verified-section__card-content">
          <div class="flex flex-row md:flex-col items-center md:items-start gap-8">
            <div class="la-haus-verified-section__icon">
              <RoomieIcon custom-styles="block w-32 h-32 md:w-48 md:h-48 text-spring-600">
                <VerifiedIcon />
              </RoomieIcon>
            </div>

            <h3
              v-if="rt(card.title)"
              class="hl-3-sb">
              {{ rt(card.title) }}
            </h3>

            <p
              v-if="rt(card.description).length < 80"
              class="body-2-sb">
              {{ rt(card.description) }}
            </p>
          </div>

          <template v-if="rt(card.description).length >= 80">
            <Transition
              name="collapse"
              @before-enter="beforeEnter"
              @enter="enter"
              @leave="leave">
              <div
                v-if="expanded[index] || rt(card.description).length < 80"
                ref="content"
                class="overflow-hidden opacity-0">
                <p class="px-40 md:px-0 body-2-sb">
                  {{ rt(card.description) }}
                </p>
              </div>

              <div
                v-else
                class="hidden md:block">
                <p class="body-2-sb">
                  {{ truncateText(rt(card.description), 80) }}
                </p>
              </div>
            </Transition>

            <div class="ml-36 md:ml-0">
              <RoomieButtonLink

                :id="`close-or-open-button-${index}`"
                variant="primary"
                @click="toggleExpand(index)">
                {{ expanded[index] ? 'Ver menos' : 'Ver más' }}
              </RoomieButtonLink>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./_index.scss" lang="scss"></style>

<i18n src="./i18n.json"></i18n>
