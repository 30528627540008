<script setup lang="ts">
import { ref } from 'vue'
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import SearchIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/search.svg'
import { LazyAppModal } from '#components'
import LocationAutocomplete from '@/components/BrandedV2/LocationAutocomplete/index.vue'
import SearchModalFilters from '@/components/BrandedV2/FormSearchFiltersModal/index.vue'
import HomeLocationSearchBarFilterButton from '@/components/BrandedV2/HomeLocationSearchBar/FilterButton/index.vue'
import type { FormSearchFiltersComponentModule } from '@/components/BrandedV2/FormSearchFilters/types'

import useRealEstateSearch from '@/components/BrandedV2/RealEstateSearch/use-search'

const emit = defineEmits(['filters-change', 'residential-complex-selected'])

const { t, locale } = useI18n()

const {
  searchTerm,
  onSubmit,
  filtersChange,
  suggestionSelected
} = useRealEstateSearch('/venta/propiedades', emit)

const isFiltersModalOpen = ref(false)

const onFiltersChange = async (filters: FormSearchFiltersComponentModule.Filters): Promise<void> => {
  isFiltersModalOpen.value = false
  await filtersChange(filters)
}

</script>

<script lang="ts">
export default {
  name: 'HomeLocationSearchBar'
}
</script>

<template>
  <div class="location-search-bar">
    <form
      class="w-full flex flex-col md:flex-row gap-12 md:gap-4 items-center"
      @submit.prevent="onSubmit">
      <div class="w-full flex flex-row gap-4 items-center">
        <LocationAutocomplete
          v-model="searchTerm"
          class="location-search-bar__autocomplete"
          container-class="w-full grid grid-cols-5 gap-4"
          input-container-class="col-span-4 md:col-span-5"
          placeholder="Busca por ciudad, zona o nombre del proyecto"
          :country-code="locale"
          :min-chars="3"
          @select="suggestionSelected">
          <HomeLocationSearchBarFilterButton
            class="md:hidden"
            @click="isFiltersModalOpen = true" />
        </LocationAutocomplete>

        <HomeLocationSearchBarFilterButton
          class="hidden md:flex"
          @click="isFiltersModalOpen = true" />
      </div>

      <RoomieButton
        id="search-filter"
        type="submit"
        class="search-filter-button"
        variant="accent">
        <span class="flex flex-row items-center justify-center gap-8">
          <span class="flex md:hidden">
            {{ t('search') }}
          </span>

          <RoomieIcon custom-styles="block w-24 h-24 text-pine-600">
            <SearchIcon />
          </RoomieIcon>
        </span>
      </RoomieButton>
    </form>

    <LazyAppModal
      v-if="isFiltersModalOpen"
      position="fixed"
      class="z-50 modal-filters"
      @close="isFiltersModalOpen = false">
      <SearchModalFilters
        screen-name="home"
        @filters-change="onFiltersChange" />
    </LazyAppModal>
  </div>
</template>

<style src="./_index.scss" lang="scss"></style>
<i18n src="./i18n.json"></i18n>
