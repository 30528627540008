export type Props = {
  id: string;
  scrollOnClick?: boolean;
  hideButtons?: boolean;
  isInfinite?: boolean;
  centerActiveElement?: boolean;
  scrollWidth?: string;
  itemsContainerClasses?: string;
  buttonClasses?:{
    left?: string;
    right?: string;
  }
};

export enum ScrollPosition {
  Center = 'center',
  Start = 'start',
  End = 'end',
}
