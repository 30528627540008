<script setup lang="ts">
import FilterIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/filter.svg'
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue'

const emit = defineEmits(['click'])
const { t } = useI18n()
</script>

<script lang="ts">
export default {
  name: 'HomeLocationSearchBarFilterButton'
}
</script>

<template>
  <button
    class="bg-white hover:bg-pine-100 focus:outline-2 focus:outline-spring-700 text-pine-600 h-46 flex flex-row justify-center items-center gap-10 p-24 md:px-40 rounded-br-[1.2rem] rounded-tr-[1.2rem] md:rounded-br-0 md:rounded-tr-0"
    type="button"
    @click="emit('click')">
    <span class="hidden md:block hl-3-m">
      {{ t('label') }}
    </span>

    <RoomieIcon custom-styles="text-pine-600">
      <FilterIcon class="fill-current-color w-24 h-24" />
    </RoomieIcon>
  </button>
</template>

<i18n src="./i18n.json"></i18n>
