<script lang="ts" setup>
import { onMounted, ref, onUnmounted, computed } from 'vue'
const { t, locale } = useI18n()

const section = ref<HTMLElement>()

let observer: IntersectionObserver | undefined

const thousand = 1000
const oneSecond = 1 * 1000

const items = computed(() => {
  return {
    mx: [
      { id: 'clients', value: 30, unit: 'mil' },
      { id: 'developers', value: 15, unit: 'mil' },
      { id: 'transactions', value: 1500, unit: 'USD' }
    ],
    co: [
      { id: 'clients', value: 30, unit: 'mil' },
      { id: 'developers', value: 1300, unit: '' },
      { id: 'transactions', value: 1500, unit: 'USD' }
    ]
  }[locale.value]
})

const animateNumber = (el:HTMLDivElement) => {
  let startTime: number | undefined
  function update (time: number) {
    if (!startTime) startTime = time

    const progress = Math.min((time - startTime) / oneSecond, 1)
    const current = Math.floor(0 + progress * (Number(el.dataset.value) - 0))

    setElementProperties(el, current)

    if (progress < 1) requestAnimationFrame(update)
  }
  requestAnimationFrame(update)
}

const setElementProperties = (element: HTMLDivElement, value: number) => {
  const thousandValue = Math.floor(value / thousand)
  const restValue = value % thousand
  if (thousandValue > 0) {
    element.style.setProperty(`--${element.id}-content`, `"+" counter(--${element.id}-thousands) "," counter(--${element.id}-rest)`)
    element.style.setProperty(`--${element.id}-thousands`, String(thousandValue))
    element.style.setProperty(`--${element.id}-rest`, String(restValue).padStart(3, '0'))
  } else {
    element.style.setProperty(`--${element.id}-content`, `"+" counter(--${element.id}-rest)`)
    element.style.setProperty(`--${element.id}-rest`, String(value))
  }
}

onMounted(() => {
  if (!CSS?.supports('counter-set', 'num 0')) {
    section.value!.classList.add('no-support')
    return
  }

  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const element = entry.target as HTMLDivElement

        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
          animateNumber(element)
        } else {
          setElementProperties(element, Number(element.dataset.value))
        }

        observer?.unobserve(element)
      }
    })
  }, { threshold: 0.5 })

  items.value.forEach((item) => {
    const element = section.value!.querySelector(`#${item.id}`)
    observer?.observe(element!)
  })
})

onUnmounted(() => {
  observer?.disconnect()
})

</script>

<script lang="ts">
export default {
  name: 'HomeTrajectoryV2'
}
</script>

<template>
  <section
    ref="section"
    class="flex flex-col justify-center w-full max-w-screen-xl gap-48 mx-auto md:gap-60 trajectory p-26 xl:px-0">
    <div class="flex flex-wrap justify-around w-full gap-40 sm:gap-56">
      <div
        v-for="item in items"
        :id="item.id"
        :key="item.id"
        :data-value="item.value"
        :class="`${item.id}__item`">
        <span class="flex flex-row items-baseline item__value">
          <span :class="{ 'display-6 md:display-5': item.unit === 'USD' }">
            {{ item.unit }}
          </span>
        </span>

        <span class="body-1-m md:hl-3-m">{{ t(item.id) }}</span>
      </div>
    </div>

    <div class="w-full p-24 text-center hl-2-sb md:display-6 text-lime-600 bg-primary-800 md:px-28 md:py-50 rounded-xl">
      {{ t("behind_us") }}
    </div>
  </section>
</template>

<style scoped lang="scss" src="./_index.scss"></style>

<i18n lang="json" src="./i18n.json"></i18n>
