import { minMaxByKey } from '@/utils/objects'
import { imageByType } from '@/components/BrandedV2/UniqueOpportunities/utils'
import type {
  UniqueOpportunitiesComposableModule
} from '@/components/BrandedV2/UniqueOpportunities/use-unique-opportunities/types'

const projectMapper = (project: Record<string, any>): UniqueOpportunitiesComposableModule.ProjectEntity => {
  let coverImage = null
  let galleryImage = null

  const { code, name, location, attributes, ims, seo } = project
  const pictureParams = '?auto=compress,enhance,format'
  const bedroomMinMax = minMaxByKey(project.ims.typologies, 'bedroom')

  const coverImageData = imageByType(project.attributes.pictures_v2, 'cover')
  const galleryImageData = imageByType(project.attributes.pictures_v2, 'gallery')

  if (coverImageData) {
    coverImage = {
      src: `${coverImageData.url}${pictureParams}&w=500&h=500&fit=crop`,
      alt: project.name
    }
  }

  if (galleryImageData) {
    galleryImage = {
      src: `${galleryImageData.url}${pictureParams}&w=500&h=500&fit=crop`,
      alt: project.name
    }
  }

  let bedroomRange = ''
  let price: number = 0

  const priceMinMax = minMaxByKey(project.ims.typologies, 'price')

  if (priceMinMax) price = priceMinMax.min

  if (bedroomMinMax) {
    bedroomRange = bedroomMinMax.isEqual ? `${bedroomMinMax.min}` : `${bedroomMinMax.min}-${bedroomMinMax.max}`
  }

  return {
    bedroomRange,
    code,
    coverImage,
    galleryImage,
    name,
    price,
    city: location.city,
    currency: attributes.currency,
    deliveredAt: ims.delivered_at,
    neighborhood: location.neighborhood,
    url: seo.path
  }
}

export default projectMapper
